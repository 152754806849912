<template>
  <div class="calculation pa-3" md="12">
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="8">
        <v-card class="pa-3 map-card" outlined>
          <map-component
            class="to-print"
            v-model:location="location"
            v-model:selectedLocation="selectedLocation"
            v-model:selectedWaterBody="selectedWaterBody"
            v-model:selectedMeasurement="selectedMeasurement"
            v-model:substance="substance"
            v-model:map="map"
            @update-bg-layers="backgroundLayers = $event"
            @reset-input="input = null"
            @update:selectedWaterBody="clickedWaterbody = $event"
          />
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4" class="calculation-input to-print">
        <v-card
          class="pa-3 ml-3 mb-3 to-print input-container"
          outlined
          height="calc(50% - 6px)"
          v-if="this.$route.params.calculationType === 'temperatuurlozing'"
        >
          <information-text />
        </v-card>
        <v-card
          class="1 pa-3 ml-3 input-container"
          outlined
          :height="this.$route.params.calculationType === 'temperatuurlozing' ? 'calc(50% - 6px)' : '100%'"
        >
          <input-fields
            v-model:location="location"
            v-model:selectedLocation="selectedLocation"
            v-model:selectedMeasurement="selectedMeasurement"
            v-model:selectedWaterBody="selectedWaterBody"
            v-model:clickedWaterbody="clickedWaterbody"
            :errorMessage="simpleErrorMessage"
            :key="customLocation"
            :map="map"
            @input-completed="updateResults"
            @set-substance="substance = $event"
            @changed-input="showResults = false"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters class="py-3 to-print" md="12" v-if="showAdvanced">
      <v-col cols="12" sm="12" md="6">
        <v-card class="pa-3 advanced-card" outlined>
          <advanced-calculation
            :location="location"
            @advanced-input-completed="updateResults"
            :errorMessage="advancedErrorMessage"
            @changed-input="showResults = false"
          />
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="6" v-if="input && showResults">
        <v-card class="pa-3 ml-3 result-card" outlined>
          <result-page
            :input="input"
            v-model:map="map"
            :backgroundLayers="backgroundLayers"
            @set-error-message="setErrorMessage"
          />
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="6" v-if="input && !showResults">
        <v-card class="pa-3 ml-3 result-card" outlined>
          Invoervelden gewijzigd, voer de berekening opnieuw uit om resultaten te zien.
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MapComponent from '@/components/MapComponent'
import InputFields from '@/components/InputFields'
import ResultPage from '@/components/ResultPage'
import AdvancedCalculation from '@/components/AdvancedCalculation'
import InformationText from '@/components/InformationText'
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'CalculationView',
  components: {
    MapComponent,
    InputFields,
    ResultPage,
    AdvancedCalculation,
    InformationText
  },
  data () {
    return {
      location: {},
      selectedWaterBody: '',
      selectedLocation: '',
      selectedMeasurement: '',
      input: null,
      substance: {},
      advancedErrorMessage: '',
      simpleErrorMessage: '',
      map: null,
      backgroundLayers: [],
      showResults: false,
      clickedWaterbody: ''
    }
  },
  watch: {
    '$route.params.calculationType' () {
      this.input = false
    },
    calcInput: {
      handler: function () {
        this.showResults = false
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['calcInput', 'advancedCalcInput', 'temperatureCalcInput', 'customLocation']),
    inputFields () {
      let inputFields = ''
      if (this.$route.params.calculationType === 'immissietoets') {
        inputFields = 'advancedCalcInput'
      } else if (this.$route.params.calculationType === 'temperatuurlozing') {
        inputFields = 'temperatureCalcInput'
      } else {
        inputFields = 'advancedCalcInput'
      }
      return this.advancedCalcInput(inputFields)
    },
    showAdvanced () {
      if (this.$route.params.calculationType === 'temperatuurlozing') {
        return _.get(this.location, 'lat')
      } else {
        return this.input
      }
    }
  },
  methods: {
    setErrorMessage (message) {
      // Either the error message belongs to a simple or advanced calculation
      if (_.get(this.input, 'effluent')) {
        this.advancedErrorMessage = message
      } else {
        this.simpleErrorMessage = message
      }
    },
    updateResults (evt) {
      this.input = evt
      this.showResults = true
    }
  }
}
</script>

<style>
.decision-tree {
  width: 100%;
  min-height: 800px;
}
.calculation {
  width: 100%;
}

.input-card {
  min-height: 800px;
  height: 800px;
}

.map-card {
  height: 800px;
}

.result-card {
  height: 100%;
}

.input-container {
  overflow-y: none;
}
</style>
