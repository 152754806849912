<script>
import _ from 'lodash'

export default {
  name: 'VMapboxInformativeVectorLayers',
  render () {
    return null
  },
  props: {
    layers: {
      type: Array,
      required: true
    }
  },
  watch: {
    layers: {
      handler () {
        this.setVectorState()
      },
      deep: true
    }
  },
  data () {
    return {
      map: null
    }
  },
  mounted () {
    this.map = this.$parent.map
    this.setVectorState()
  },
  methods: {
    setVectorState () {
      // Toggle the visibility of the layers in the layer list. When the layer
      // is not present on the map add to the map.
      if (!this.map) {
        return
      }

      this.layers.forEach(layer => {
        // Check for each layer if present, remove or add layers/sources where needed
        const sourcePresent = this.map.getSource(_.get(layer, 'source.id'))
        if (!sourcePresent && layer.active) {
          this.map.addSource(_.get(layer, 'source.id'), _.get(layer, 'source.data'))
        }

        layer.layers.forEach(childLayer => {
          // Check for each layer in this group whether the layer was added to the map
          // otherwise add the layers to the map
          const layerPresent = this.map.getLayer(childLayer.id)
          if (layerPresent && !layer.active) {
            this.map.removeLayer(childLayer.id)
          }

          if (layerPresent && layer.active) {
            this.map.removeLayer(childLayer.id)
            this.map.removeSource(_.get(layer, 'source.id'))
            this.map.addSource(_.get(layer, 'source.id'), _.get(layer, 'source.data'))
            this.map.addLayer(childLayer)
          }
          // Always add the background layer after the last mapbox layer
          if (!layerPresent && layer.active) {
            this.map.addLayer(childLayer)
          }
        })
        if (sourcePresent && !layer.active) {
          this.map.removeSource(_.get(layer, 'source.id'))
        }
      })
    }
  }
}
</script>

<style>
.mapboxgl-popup-content {
  width: fit-content;
}
</style>
