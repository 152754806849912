<template>
  <div class="results-temperature to-print">
    <v-tabs
      v-model="activeTab"
      bg-color="navbar"
      color="text"
      center-active
      show-arrows
      class="not-to-print"
      grow
    >
      <v-tab href="#results"> Resultaten </v-tab>
      <v-tab href="#tree"> Beslisboom </v-tab>
      <v-tab v-if="segments.length > 0" href="#map"> Kaart </v-tab>
      <v-tab v-if="logs.length > 0" href="#logs"> Logs </v-tab>
      <v-tab href="#pdf">PDF</v-tab>
    </v-tabs>

    <v-window v-model="activeTab" class="tabs-items">
      <v-window-item v-if="graphs.length > 0" value="results" eager>
        <div class="ma-3 to-print result-graph">
          <h2>Grafische weergave temperatuurlozing
            <v-tooltip bottom width="200px" >
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props">mdi-information-outline</v-icon>
              </template>
              <span>
                Pluimmenging: Berekende temperatuurverschil als functie van de afstand ten gevolge van lokale menging, zonder uitwisselingseffecten met de atmosfeer.
                Omgevingsmenging: Berekende temperatuurverschil als functie van de afstand na volledige menging (einde pluimmenging) inclusief warmteuitwisseling met de atmosfeer (thermische profiel).
              </span>
            </v-tooltip>
          </h2>
          <v-row class="pa-3" v-for="(graph, index) in graphs" :key="index">
            <div class="graph-div" v-html="graph"></div>
          </v-row>
          {{ resultText }}
          <v-row class="pa-3">
            <data-table class="result-table" :tableHeaders="tableHeaders" :tableItems="tableItems" :hideDefaultFooter="false"/>
          </v-row>
        </div>
      </v-window-item>
      <v-window-item value="tree" eager>
        <div class="ma-3">
          <v-row no-gutters>
            <v-col md="12">
              <decision-tree :steps="results" class="to-print decision-tree"/>
            </v-col>
          </v-row>
        </div>
      </v-window-item>
      <v-window-item value="map" eager>
        <div v-if="input.water.watertype !== 5" class="ma-3 to-print">
          <h2>Verspreiding temperatuurlozing</h2>
          <span>
            In deze kaart is weergeven wat het effect van de temperatuurlozing is door met kleuren aan te geven welke temperatuurverhoging (in graden Celsius) op welke locaties verwacht kan worden.
            De schematische weergave is gebaseerd op het Nationaal Water Model (NWM).
          </span>
          <v-row
            class="pa-3"
            style="display: flex; height: 400px; overflow: auto"
          >
          <div class="map">
            <mapbox-map
              :access-token="mapboxToken"
              map-style="mapbox://styles/mapbox/light-v10?optimize=true"
              class="map"
              ref="resultMap"
              :interactive="false"
              :fitBoundsOptions="this.resultBbox"
            >
              <v-mapbox-background-layers :layers="backgroundLayers" />
              <v-mapbox-informative-vector-layers :layers="segments" />
            </mapbox-map>
          </div>
          </v-row>
          <map-legend :legendColors="legendColors" :legendText="legendText" />
        </div>
        <v-alert v-else  type="info" dense outlined class="ma-3 to-print">
          Kaartresultaten kunnen niet berekend worden voor watertype: Doodlopende kanaalpanden en havens (zonder restdebiet).
        </v-alert>
      </v-window-item>
      <v-window-item v-if="logs.length > 0" value="logs" class="not-to-print">
        <div class="ma-3" style="display: flex; height: 800px; overflow: auto">
          <pre>
            <p v-html="logs" />
          </pre>
        </div>
      </v-window-item>
      <v-window-item value="pdf" class="not-to-print">
        <div class="ma-3">
          <h2>
            Exporteer pagina naar pdf
          </h2>
          <v-row class="pa-3">
            <print-component />
          </v-row>
        </div>
      </v-window-item>
    </v-window>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import DecisionTree from '@/components/DecisionTree'
import VMapboxInformativeVectorLayers from './VMapbox/VMapboxInformativeVectorLayers'
import VMapboxBackgroundLayers from './VMapbox/VMapboxBackgroundLayers'
import PrintComponent from '@/components/PrintComponent'
import MapLegend from './MapLegend'
import bbox from '@turf/bbox'
import DataTable from '@/components/DataTable'
import { MapboxMap } from '@studiometa/vue-mapbox-gl'

export default {
  props: {
    input: {
      type: Object
    },
    map: {
      type: Object,
      required: true
    },
    backgroundLayers: {
      type: Array
    }
  },
  data () {
    return {
      mapboxToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
      tab: null,
      activeTab: 0,
      graphs: [],
      logs: '',
      graphImage: '',
      resultText: '',
      results: [],
      segments: [],
      resultMap: {},
      resultBbox: [],
      tableHeaders: [
        {
          text: 'Temperatuurverschil [°C]',
          align: 'left',
          sortable: false,
          value: 'temperature_difference',
          class: 'primary'
        },
        {
          text: 'Op afstand [m]',
          style: 'font-color: blue',
          align: 'left',
          sortable: false,
          value: 'at_distance',
          class: 'primary'
        }
      ],
      tableItems: []
    }
  },
  watch: {
    input () {
      this.getCalculation()
    }
  },
  mounted () {
    this.getCalculation()
    this.resultMap = _.get(this.$refs.resultMap, 'map')
  },
  components: {
    DecisionTree,
    VMapboxInformativeVectorLayers, // Simple vector layers which only need displaying
    VMapboxBackgroundLayers,
    MapLegend,
    DataTable,
    PrintComponent,
    MapboxMap
  },
  computed: {
    legendColors () {
      // If cold dump else hot dump
      if (_.get(this.input, 'effluent.e_temperature_diff_effluent_ambient') < 0) {
        /* eslint max-len: ["error", { "code": 385, "tabWidth": 4 }] */
        return 'background: linear-gradient(to left, #251aff 0%, #251aff 17%, #4e1aca 17%, #4e1aca 33%, #831985 33%, #831985 50%, #ae184d 50%, #ae184d 67%, #d71818 67%, #d71818 83%, #f6aaaa 83%)'
      } else {
        return 'background: linear-gradient(to right, #251aff 0%, #251aff 16%, #4a1ace 16%, #4a1ace 33%, #6f199e 33%, #6f199e 50%, #911972 50%, #911972 66%, #b11948 66%, #b11948 84%, #d71818 84%)'
      }
    },
    legendText () {
      // If cold dump else hot dump
      if (_.get(this.input, 'effluent.e_temperature_diff_effluent_ambient') < 0) {
        return '-0.0 -0.1 -0.2 -0.5 -1.0 -2.0 -4.0'
      } else {
        return '0.05 0.1 0.2 0.5 1.0 2.0 5.0 8.0'
      }
    }
  },
  methods: {
    getCalculation () {
      this.$emit('set-error-message', '')
      // Fetch simple or advanced calculation
      const endpoint = 'calculate_coldwater'
      fetch(`${process.env.VUE_APP_SERVER_URL}/${endpoint}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.input)
      })
        .then(res => {
          // If the status code is not 200, return error message
          if (res.status === 400) {
            this.$emit('set-error-message', 'Er is een fout in de invoervelden.')
          }
          return res.json()
        })
        .then(response => {
          if (_.get(response, 'message_nl')) {
            this.$emit('set-error-message', _.get(response, 'message_nl'))
            return
          }
          // Check if the response contains either a result or an error message
          if (!response.detail) {
            this.$emit('update:correctResponseTime', `Laatste correcte berekening: ${moment().format('HH:mm:ss DD-MM-YYYY')}`)
            if (_.get(response, 'graphs')) {
              this.results = response.details
              this.graphs = response.graphs
            } else {
              this.results = response
              this.graphs = []
            }

            if (!isNaN(_.get(response, 'temperature_change'))) {
              let validationText = 'Het temperatuurverschil op de maximale mengzone afstand komt niet meer binnen de toegestane grens van de pluimberekening.'
              let cwdText = 'De opgegeven mengzone afstand ligt te ver van de punt van lozing, het temperatuurverschil is daar verwaarloosbaar.'
              if (response.temperature_valid_location || parseFloat(response.temperature_valid_location) === 0.0) {
                validationText = `In de pluimberekening valt op ${response.temperature_valid_location}m van de lozing
                het temperatuurverschil binnen het toegestane temperatuurverschil.`
              }
              if (response.temperature_at_length_cwd || parseFloat(response.temperature_at_length_cwd) === 0.0) {
                cwdText = `In de berekening is op ${this.input.mixing_zone.m_length_cwd}m (de gebruikers gedefinieerde toetslengte) van de lozing
                het temperatuurverschil ${response.temperature_at_length_cwd} °C.`
              }
              this.resultText = `De afstand waarop de pluimmenging ophoudt is op ${response.max_mixingzone_distance} meter bij ${response.temperature_change} °C.
              ${validationText} ${cwdText}`
            } else {
              this.resultText = 'Geen temperatuurverschil berekend.'
            }

            if (_.get(response, 'logs')) {
              this.logs = response.logs
            }

            if (_.get(response, 'nwm_segments')) {
              this.segments = []
              // Create random number based on timestamp to have a unique id to be used for refreshing the <v-mapbox-informative-vector-layers>
              // every time we have a new result from the advanced calculation
              const semgentsId = Date.now()
              let paint = {
                'line-width': ['interpolate', ['linear'], ['zoom'], 0, 1, 22, 10],
                'line-color': [
                  'step', ['get', 'result_concentration'],
                  '#251aff', -4.0,
                  '#251aff', -2.001,
                  '#4e1aca', -2.0,
                  '#4e1aca', -1.001,
                  '#831985', -1.0,
                  '#831985', -0.5001,
                  '#ae184d', -0.5,
                  '#ae184d', -0.2001,
                  '#d71818', -0.2,
                  '#d71818', -0.101,
                  '#f6aaaa', -0.1,
                  '#f6aaaa', -0.0,
                  '#000'
                ]
              }
              if (_.get(this.input, 'effluent.e_temperature_diff_effluent_ambient') >= 0) {
                paint = {
                  'line-width': ['interpolate', ['linear'], ['zoom'], 0, 1, 22, 10],
                  'line-color': [
                    'step', ['get', 'result_concentration'],
                    '#251aff', 0.1,
                    '#251aff', 0.1999,
                    '#4a1ace', 0.2,
                    '#4a1ace', 0.2999,
                    '#6f199e', 0.5,
                    '#6f199e', 0.9999,
                    '#911972', 1.0,
                    '#911972', 4.999,
                    '#b11948', 5.0,
                    '#b11948', 7.9999,
                    '#d71818', 8.0,
                    '#d71818', 50.0,
                    '#000'
                  ]
                }
              }
              this.segments = [{
                id: semgentsId,
                source: {
                  data: {
                    data: response.nwm_segments,
                    type: 'geojson'
                  },
                  id: 'segment-results'
                },
                layers: [{
                  id: 'segment-results',
                  source: 'segment-results',
                  type: 'line',
                  paint
                }],
                active: true
              }]

              this.tableItems = response.temperature_valid_locations
              const bounds = bbox(response.nwm_segments)
              this.resultBbox = [[bounds[0], bounds[1]], [bounds[2], bounds[3]]]
              if (this.resultMap) {
                this.resultMap.fitBounds(this.resultBbox)
              }
            }
          } else {
            let message = 'De berekening kon niet worden uitgevoerd en gaf de volgende melding: \n'
            response.detail.forEach(det => {
              message = `${message}${det.msg}\n`
            })
            this.$emit('set-error-message', message)
          }
        })
    }
  }
}
</script>

<style>
.tabs-items {
  height: auto;
}

.graph-div {
  width: 100%;
}

.map {
  width: 100%;
  height: 100%;
}

@media print {
  .jqx-tabs-content {
    position: absolute;
    height: 2000px;
  }

  .v-window-item {
      display: block !important;
      height: auto !important;
  }
}

</style>
