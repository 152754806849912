import { createStore } from 'vuex'
import _ from 'lodash'
import router from '../router'

const emptyFeatureCollection = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [0, 0]
      }
    }
  ]
}

const retrieveAdvancedProperties = (advancedProperties, response) => {
  const requiredFields = []
  Object.entries(advancedProperties).forEach(entry => {
    let ref = _.get(entry[1], '$ref')
    if (ref) {
      ref = ref.replace('#/', '')
      ref = ref.replaceAll('/', '.')
      advancedProperties[entry[0]].properties = _.get(response, `${ref}.properties`)
      advancedProperties[entry[0]].title = _.get(response, `${ref}.title`)
      requiredFields.push(_.get(response, `${ref}.required`))
      Object.entries(advancedProperties[entry[0]].properties).forEach(prop => {
        prop[1].data = _.get(prop[1], 'default', null)
      })
    }
  })
  return requiredFields
}

export default createStore({
  state: {
    informativeLayers: [],
    interactiveLayers: [],
    locationsList: [],
    waterbodyList: [],
    measurementsList: [],
    calcInput: {},
    advancedCalcInput: {},
    temperatureCalcInput: {},
    drinkwaterIntakeList: [],
    emptyFeatureCollection: Object.assign({}, emptyFeatureCollection),
    intakeInput: {},
    locationDetails: [],
    requiredAdvancedFields: [],
    requiredTemperatureFields: [],
    waterbodiesDetails: [],
    version: 0,
    regionalWaterBodies: {},
    watertypeSelectionItems: [],
    customLocation: false
  },
  mutations: {
    addInformativeLayer (state, layer) {
      state.informativeLayers.push(layer)
    },
    removeInformativeLayer (state, layerName) {
      state.informativeLayers = state.informativeLayers.filter(layer => layer.name !== layerName)
    },
    setLocationsList (state, locations) {
      state.locationsList = locations
    },
    setLocationDetails (state, details) {
      state.locationDetails = details
    },
    setWaterbodiesDetails (state, details) {
      state.waterbodiesDetails = details
    },
    setWaterbodyList (state, waterbodies) {
      state.waterbodyList = waterbodies
    },
    setMeasurementsList (state, measurements) {
      state.measurementsList = measurements
    },
    setDrinkwaterIntakeList (state, drinkwaterIntakes) {
      state.drinkwaterIntakeList = drinkwaterIntakes
    },
    addInteractiveLayer (state, layer) {
      state.interactiveLayers.push(layer)
    },
    setCalcInput (state, form) {
      // This hard reseting of the calcinput is needed otherwise the corresponding
      // input fields v-models won't respond to a deep change in the object.
      state.calcInput = {}
      state.calcInput = form
    },
    setAdvancedCalcInput (state, form) {
      state.advancedCalcInput = form
    },
    setTemperatureCalcInput (state, form) {
      state.temperatureCalcInput = form
    },
    setIntakeInput (state, intake) {
      const segmentId = _.get(state.intakeInput, 'segment_id')
      state.intakeInput = intake
      if (_.get(segmentId, 'data')) {
        state.intakeInput.segment_id = segmentId
      }
    },
    setRequiredAdvancedFields (state, fields) {
      state.requiredAdvancedFields = fields
    },
    setRequiredTemperatureFields (state, fields) {
      state.requiredTemperatureFields = fields
    },
    addAdvancedCalcInput (state, { param, group, props }) {
      Object.entries(props).forEach(prop => {
        _.set(state.advancedCalcInput, `${group}.properties.${param}.${prop[0]}`, prop[1])
      })
    },
    addTemperatureCalcInput (state, { param, group, props }) {
      Object.entries(props).forEach(prop => {
        _.set(state.temperatureCalcInput, `${group}.properties.${param}.${prop[0]}`, prop[1])
      })
    },
    setVersion (state, version) {
      state.version = version
    },
    setRegionalWaterbodies (state, properties) {
      state.regionalWaterBodies = {}
      state.regionalWaterBodies = properties
    },
    setCustomLocation (state, customLocation) {
      state.customLocation = customLocation
    }
  },
  getters: {
    calcInput (state) {
      return state.calcInput
    },
    totalAdvancedCalcInput: (state) => (inputFields) => {
      return state[inputFields]
    },
    groupTitles: (state) => (inputFields) => {
      const titles = {}
      Object.entries(state[inputFields]).forEach(entry => {
        titles[entry[0]] = entry[1].title
      })
      return titles
    },
    advancedCalcInput: (state) => (inputFields) => {
      // Only get the advancedCalcInput for the selected watertype and when tracer
      // present. Since advancedCalcInput is a nested object, need to loop over to
      // filter it properly
      const watertype = _.get(state, 'calcInput.watertype.data', 1)
      const krwWatertype = _.get(state, 'temperatureCalcInput.water.properties.w_krw_water_type.data') ||
        _.get(state, 'regionalWaterBodies.w_krw_water_type')

      const advInputs = {}
      Object.entries(state[inputFields]).forEach(entry => {
        advInputs[entry[0]] = {}
        Object.entries(entry[1].properties).forEach(prop => {
          const watertypes = _.get(prop[1], 'properties.watertypes', [])
          const krwWatertypes = _.get(prop[1], 'properties.krw_watertypes')
          // Use residence time to check whether the value should be shown (show_when_tracer === true) or not.
          const optionals = _.get(prop[1], 'properties.optional_when_tracer', [])
          const optionalWatertype = optionals.includes(watertype)
          const showForWatertypes = watertypes.includes(watertype)

          let showForKrwWatertypes = true
          if (krwWatertypes) {
            showForKrwWatertypes = inputFields === 'temperatureCalcInput' ? krwWatertypes.includes(krwWatertype) : true
          }

          const hasResidenceTime = _.get(state.locationDetails, 'w_residence_time')

          let showForResidenceTime = false
          let showForNoResidenceTime = false
          // Show when optional watertype, when residence time has value, and when show_when_tracer ===true
          // Show when optional watertype, when residence time is null and when show_when_tracer === false
          if (optionalWatertype && hasResidenceTime) {
            showForResidenceTime = _.get(prop[1], 'properties.show_when_tracer')
          } else if (optionalWatertype && hasResidenceTime === null) {
            showForNoResidenceTime = !(_.get(prop[1], 'properties.show_when_tracer'))
          }

          // Do the check on either watertype or residence time
          if ((showForWatertypes || showForResidenceTime || showForNoResidenceTime) && showForKrwWatertypes) {
            advInputs[entry[0]][prop[0]] = prop[1]
          }
        })
      })
      return advInputs
    },
    intakeInput (state) {
      return state.intakeInput
    },
    locationDetails (state) {
      return state.locationDetails
    },
    waterbodiesDetails (state) {
      return state.waterbodiesDetails
    },
    requiredAdvancedFields (state) {
      return state.requiredAdvancedFields
    },
    requiredTemperatureFields (state) {
      return state.requiredTemperatureFields
    },
    regionalWaterBodies (state) {
      return state.regionalWaterBodies
    },
    interactiveLayers (state) {
      const layers = state.interactiveLayers
      if (layers.length === 0) {
        return []
      }
      const calculationType = router.currentRoute._value.params.calculationType
      if (calculationType === 'immissietoets') {
        return layers
      } else if (calculationType === 'temperatuurlozing') {
        layers.forEach(layer => {
          if (layer.name === 'Meetpunten') {
            layer.active = false
          }
        })
        return layers.filter(layer => layer.name !== 'Meetpunten')
      } else {
        return layers
      }
    },
    watertypeSelectionItems (state) {
      return state.watertypeSelectionItems
    },
    customLocation (state) {
      return state.customLocation
    }
  },
  actions: {
    loadWatertypeSelectionItems (store) {
      const url = `${process.env.VUE_APP_SERVER_URL}/locationtypes/`
      return fetch(url)
        .then(res => {
          return res.json()
        })
        .then(response => {
          store.state.watertypeSelectionItems = _.orderBy(response, ['watertype_id'], ['asc'])
        })
    },
    loadBackgroundPoints (store, substanceId) {
      // Remove old backgrounds measurements locations
      store.state.interactiveLayers = store.state.interactiveLayers.filter(layer => layer.name !== 'Meetpunten')

      // If there is no substance ID, we don't want to get all background_geojson.
      if (!substanceId) {
        return
      }
      let url = `${process.env.VUE_APP_SERVER_URL}/backgrounds_geojson/`
      url = `${url}?substance_id=${substanceId}`
      store.state.interactiveLayers = store.state.interactiveLayers.filter(layer => layer.name !== 'Meetpunten')

      store.commit('addInteractiveLayer', {
        name: 'Meetpunten',
        active: true,
        source: {
          id: 'background-geojson',
          data: {
            type: 'geojson',
            data: url
          }
        },
        layers: [{
          id: 'background-geojson',
          type: 'circle',
          source: 'background-geojson',
          minzoom: 10,
          paint: {
            'circle-color': [
              'case',
              ['==', ['get', 'source'], 'wkp'],
              '#D8F2E8',
              '#FFF2F2'
            ],
            'circle-stroke-width': 3,
            'circle-stroke-color': '#4F5759'
          }
        }]
      })
    },
    loadInformativeLayers (store) {
      // fetch informative layers from backend
      store.commit('addInformativeLayer', {
        active: false,
        name: 'Drinkwater inname',
        source: {
          id: 'drinkwater-intake-points',
          data: {
            type: 'geojson',
            data: `${process.env.VUE_APP_SERVER_URL}/drinkwater_intake_points_geojson/`
          }
        },
        layers: [
          {
            id: 'drinkwater-intake-points-areas',
            type: 'fill',
            source: 'drinkwater-intake-points',
            minzoom: 10,
            filter: [
              'match',
              ['geometry-type'],
              ['', 'Polygon'],
              true,
              false
            ],
            layout: {},
            paint: {
              'fill-color': '#4F5759',
              'fill-opacity': 0.75,
              'fill-outline-color': '#4F5759'
            }
          },
          {
            id: 'drinkwater-intake-points-circle',
            type: 'circle',
            source: 'drinkwater-intake-points',
            minzoom: 10,
            filter: ['match', ['geometry-type'], ['', 'Point'], true, false],
            layout: {},
            paint: {
              'circle-color': '#4F5759',
              'circle-stroke-width': 3,
              'circle-stroke-color': 'white'
            }
          },
          {
            id: 'drinkwater-intake-points-text',
            type: 'symbol',
            source: 'drinkwater-intake-points',
            filter: ['match', ['geometry-type'], ['', 'Point'], true, false],
            minzoom: 10,
            layout: {
              'text-field': ['to-string', ['get', 'name_item']],
              'text-anchor': 'bottom-left',
              'text-radial-offset': 1,
              'text-pitch-alignment': 'map'
            },
            paint: {
              'text-halo-color': 'white',
              'text-halo-width': 2,
              'text-halo-blur': 2
            }
          }]
      })
    },
    loadCalcInput (store) {
      // Retrieve the ionput fields required for the calculate endpoint
      return fetch(`${process.env.VUE_APP_SERVER_URL}/openapi.json`)
        .then(res => {
          return res.json()
        })
        .then(response => {
          // save the version number in the state
          store.commit('setVersion', _.get(response, 'info.version'))

          // save the openapi spec as documenatation (calcInput for simple
          // calculation, AdvancedCalcInput for advanced calculation)
          let calcProperties = response.components.schemas.CalcInput.properties
          calcProperties = _.omit(calcProperties, 'type')
          Object.entries(calcProperties).forEach(prop => {
            prop[1].data = _.get(prop[1], 'default', null)
          })

          store.commit('setCalcInput', calcProperties)

          // The advanced input is a nested input consisting from three models, retrieve all
          // three and store in nested object.
          const advancedProperties = response.components.schemas.AdvancedCalcInput.properties
          // Also store the requiredfields in one big list
          const requiredAdvancedFields = retrieveAdvancedProperties(advancedProperties, response)
          // Flatten the list of required fields and store
          store.commit('setRequiredAdvancedFields', requiredAdvancedFields.flat())
          // advancedProperties = .differenceWith(advancedProperties, calcProperties, .isEqual)
          store.commit('setAdvancedCalcInput', advancedProperties)

          // Same for the Temperature dump calculation
          const tempProperties = response.components.schemas.ColdwaterCalcInput.properties
          const requiredTempFields = retrieveAdvancedProperties(tempProperties, response)
          store.commit('setRequiredTemperatureFields', requiredTempFields.flat())
          store.commit('setTemperatureCalcInput', tempProperties)

          // save the openapi spec as documenatation (calcInput for simple
          // calculation, AdvancedCalcInput for advanced calculation)
          const intakeProperties = response.components.schemas.CalcInputConcentration.properties
          Object.entries(intakeProperties).forEach(prop => { prop[1].data = _.get(prop[1], 'default', null) })
          store.commit('setIntakeInput', intakeProperties)
        })
    },
    loadInteractiveLayers (store) {
      // fetch interactive layers from backend
      store.commit('addInteractiveLayer', {
        name: 'Gebiedssegmenten o.b.v. lokale modellen',
        active: true,
        source: {
          id: 'locations-geojson',
          data: {
            type: 'geojson',
            data: `${process.env.VUE_APP_SERVER_URL}/locations_geojson/`
          }
        },
        layers: [{
          id: 'locations-line',
          type: 'line',
          source: 'locations-geojson',
          minzoom: 10,
          paint: {
            'line-color': '#4F5759',
            'line-width': 1
          },
          layout: {
            'line-join': 'bevel',
            'line-cap': 'round'
          }
        },
        {
          id: 'locations',
          type: 'fill',
          source: 'locations-geojson',
          minzoom: 10,
          paint: {
            'fill-color': 'transparent'
          }
        }
        ]
      })

      store.commit('addInteractiveLayer', {
        name: 'Waterlichamen',
        active: true,
        source: {
          id: 'waterbodies-geojson',
          data: {
            type: 'geojson',
            data: `${process.env.VUE_APP_SERVER_URL}/waterbodies_geojson/`
          }
        },
        layers: [
          {
            id: 'waterbodies-geojson-line',
            type: 'line',
            source: 'waterbodies-geojson',
            minzoom: 10,
            paint: {
              'line-color': '#367DD9',
              'line-width': 3
            },
            layout: {
              'line-join': 'bevel',
              'line-cap': 'round'
            }
          },
          {
            id: 'waterbodies-geojson',
            type: 'fill',
            source: 'waterbodies-geojson',
            minzoom: 10,
            paint: {
              'fill-color': 'transparent'
            }
          }
        ]
      })

      store.commit('addInteractiveLayer', {
        name: 'Lijnsegmenten o.b.v. Nationaal Water Model (NWM)',
        active: false,
        source: {
          id: 'nwm-segments-geojson',
          data: {
            type: 'geojson',
            data: `${process.env.VUE_APP_SERVER_URL}/nwm_line_segments_geojson/`
          }
        },
        layers: [{
          id: 'nwm-segments-geojson',
          type: 'line',
          source: 'nwm-segments-geojson',
          minzoom: 10,
          paint: {
            // 'fill-color': 'grey',
            // 'fill-opacity': 0.5
            'line-color': '#4F5759',
            'line-dasharray': [1, 1],
            'line-width': 2
          }
        }, {
          id: 'nwm-segments-geojson-arrow',
          type: 'circle',
          source: 'nwm-segments-geojson',
          minzoom: 10,
          paint: {
            'circle-color': '#4F5759',
            'circle-stroke-color': '#4F5759',
            'circle-radius': 2
          }
        }]
      })
    },

    loadBackgroundList (store, substanceId) {
      let url = `${process.env.VUE_APP_SERVER_URL}/backgrounds/?limit=10000`
      if (substanceId) {
        url = `${url}&substance_id=${substanceId}`
      }
      fetch(url)
        .then(res => {
          return res.json()
        })
        .then(response => {
          store.commit('setMeasurementsList', response)
        })
    },
    loadWaterbodiesList (store) {
      fetch(`${process.env.VUE_APP_SERVER_URL}/waterbodies/?limit=750`)
        .then(res => {
          return res.json()
        })
        .then(response => {
          store.commit('setWaterbodyList', response)
        })
    },
    loadDrinkwaterIntakeList (store) {
      fetch(`${process.env.VUE_APP_SERVER_URL}/drinkwater_intake_points/`)
        .then(res => {
          return res.json()
        })
        .then(response => {
          store.commit('setDrinkwaterIntakeList', response)
        })
    },
    loadLocationsList (store, description) {
      let url = `${process.env.VUE_APP_SERVER_URL}/locations/?limit=10000`
      if (description) {
        url = `${url}&description=${description}`
        fetch(url)
          .then(res => {
            return res.json()
          })
          .then(response => {
            store.commit('setLocationsList', response)
          })
      }
    },
    loadWaterbody (store, waterbodyId) {
      // Get the data through the background id
      fetch(`${process.env.VUE_APP_SERVER_URL}/waterbodies/${waterbodyId}`)
        .then(res => {
          return res.json()
        })
        .then(response => {
          store.commit('setWaterbodiesDetails', {
            w_flow_krw_waterbody: response.w_flow_krw_waterbody
          })
        })
    },
    loadRegionalWaterbodies (store, { lng, lat }) {
      // Get regional waterbodies in geojson format
      const url = `${process.env.VUE_APP_SERVER_URL}/regional_waterbodies_geojson/?longitude=${lng}&latitude=${lat}`
      fetch(url)
        .then(res => {
          return res.json()
        })
        .then(response => {
          const props = _.get(response, 'features[0].properties', [])
          store.commit('setRegionalWaterbodies', props)
        })

      const layerName = 'Regionale waterlichamen'
      const layer = store.state.informativeLayers.find(layer => layer.name === layerName)
      const layerState = _.get(layer, 'active', false)
      store.commit('removeInformativeLayer', layerName)
      store.commit('addInformativeLayer', {
        active: layerState,
        name: layerName,
        source: {
          id: 'regional-waterbodies-src',
          data: {
            type: 'geojson',
            data: url
          }
        },
        layers: [
          {
            id: 'regional-waterbodies',
            type: 'line',
            source: 'regional-waterbodies-src',
            minzoom: 10,
            layout: {},
            paint: {
              'line-color': '#ce7e00',
              'line-width': 4
            }
          }]
      })
    }
  }
})
