<template>
  <div class="advanced">
    <h2>Geavanceerde berekening - {{ $route.params.calculationType }}</h2>
    <v-form ref="advancedForm" class="mb-3">
      <v-card
        v-for="(group, groupKey) in groupedInputFields"
        :key="groupKey"
        flat
        width="100%"
        >
        <h2>{{ toTitle(groupTitles(inputFields)[groupKey]) }}</h2>
        <v-divider></v-divider>
        <div v-for="(subGroup, subGroupKey) in group" :key="subGroupKey" class="pb-3">
          <h4 v-if="Object.keys(filteredSubGroup(subGroup)).length !== 0" class="pb-3"> {{ toTitle(subGroupKey) }} </h4>
          <v-row density="compact">
            <v-col
              cols="12"
              sm="12" :md="input.items ? '12' : '4'" xs="12"
              class="input-field"
              v-for="(input, inputName) in filteredSubGroup(subGroup)"
              :key="inputName"
            >
            <v-checkbox
              :class="`pa-0 ma-0 checkbox ${input.title}`"
              density="compact"
              variant="underlined"
              v-if="input.type === 'boolean'"
              :label="input.description || input.title"
              v-model="input.data"
              :bg-color="checkUpdateField(input)"
              >
              <template v-slot:append>
                <v-tooltip max-width="500px" v-if="input.properties.info_nl" right>
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props">mdi-information-outline</v-icon>
                  </template>
                  <span>{{input.properties.info_nl}}</span>
                </v-tooltip>
              </template>
            </v-checkbox>
            <v-row v-else-if="input.items">
              <v-col cols=3>
              <v-text-field
                :class="input.title"
                density="compact"
                :label="input.description || input.title"
                v-model="input.data"
                :suffix="input.properties ? input.properties.unit : undefined"
                :flat="simpleCalcInput(inputName)"
                :readonly="simpleCalcInput(inputName)"
                variant="underlined"
                :append-icon="input.data != input.default && input.default && !simpleCalcInput(inputName) ?
                  'mdi-refresh' : undefined"
                :append-outer-icon="input.properties.relation ? 'mdi-alert-circle-outline' : undefined"
                @click:append="input.data = input.default;
                  input.selectedItem = input.items[0]"
                @input="checkRelation(input);
                  input.selectedItem = input.items[input.items.length -1]"
                :bg-color="checkUpdateField(input)"
                :rules="[
                  requiredField(inputName) ? required(input.description || input.title) : true,
                  type(input),
                  minmax(input)
                ]">
                <template v-slot:append>
                  <v-tooltip max-width="500px" v-if="input.properties.info_nl" right>
                    <template v-slot:activator="{ props }">
                      <v-icon v-bind="props">mdi-information-outline</v-icon>
                    </template>
                    <span>{{input.properties.info_nl}}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols=9>
              <v-select
                density="compact"
                :items="input.items"
                :item-title="(norm) =>  `${norm.norm_description} ${norm.value ? '(' + norm.value + ')' : ''}`"
                item-value="norm_description"
                :return-object="true"
                v-model="input.selectedItem"
                variant="underlined"
                :bg-color="checkUpdateField(input)"
                :append-icon="(input.data != input.default)
                  && input.default && !simpleCalcInput(inputName) ?
                  'mdi-refresh' : undefined"
                @click:append="input.data = input.default;
                  input.selectedItem = input.items[0];
                  updateAdvancedInput(inputName, input.data, groupKey)"
                @update:modelValue="input.data = input.selectedItem.value;
                  checkRelation(input)"
              >
              <template v-slot:append>
                <v-tooltip max-width="500px" v-if="input.properties.info_nl" right>
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props">mdi-information-outline</v-icon>
                  </template>
                  <span>{{input.properties.info_nl}}</span>
                </v-tooltip>
              </template>
              </v-select>
            </v-col>
            </v-row>
            <!-- use a combobox whenever a enumerate object is passed -->
              <v-select
                :class="input.title"
                density="compact"
                v-else-if="input.properties.enum_names"
                :label="input.description || input.title"
                v-model="input.data"
                :items="input.properties.enum_names"
                item-value="id"
                item-title="name"
                variant="underlined"
                :append-icon="input.data != input.default && input.default && !simpleCalcInput(inputName) ?
                  'mdi-refresh' : undefined"
                :suffix="input.properties ? input.properties.unit : undefined"
                :flat="simpleCalcInput(inputName)"
                :readonly="simpleCalcInput(inputName)"
                :return-object="false"
                :bg-color="checkUpdateField(input)"
                @click:append="input.data = input.default;
                  updateAdvancedInput(inputName, input.data, groupKey);"
                @update:modelValue="updateAdvancedInput(inputName, input.data, groupKey)"
                :rules="[
                  requiredField(inputName) ? required(input.description || input.title) : true,
                  type(input),
                  minmax(input)]"
              >
                <v-tooltip max-width="500px" v-if="input.properties.info_nl" right>
                  <template v-slot:activator="{ props }">
                    <v-icon v-bind="props">mdi-information-outline</v-icon>
                  </template>
                  <span>{{input.properties.info_nl}}</span>
                </v-tooltip>
              </v-select>
              <v-text-field
                :class="input.title"
                density="compact"
                v-else
                :label="input.description || input.title"
                v-model="input.data"
                :suffix="input.properties ? input.properties.unit : undefined"
                :flat="simpleCalcInput(inputName)"
                :readonly="simpleCalcInput(inputName)"
                variant="underlined"
                :append-icon="input.data != input.default && input.default && !simpleCalcInput(inputName) ?
                  'mdi-refresh' : undefined"
                :append-outer-icon="input.properties.relation ? 'mdi-alert-circle-outline' : undefined"
                @click:append="input.data = input.default;
                  updateAdvancedInput(inputName, input.default, groupKey)"
                @input="checkRelation(input)"
                :bg-color="checkUpdateField(input)"
                :rules="[
                  requiredField(inputName) ? required(input.description || input.title) : true,
                  type(input),
                  minmax(input)]"
                >
                <template v-slot:append>
                  <v-tooltip max-width="500px" v-if="input.properties.relation">
                    <template v-slot:activator="{ props }">
                      <v-icon v-bind="props">mdi-information-outline</v-icon>
                    </template>
                    <span>{{ input.properties.relation.info }} </span>
                  </v-tooltip>
                  <v-tooltip max-width="500px" v-if="input.properties.info_nl" right>
                    <template v-slot:activator="{ props }">
                      <v-icon v-bind="props">mdi-information-outline</v-icon>
                    </template>
                    <span>{{input.properties.info_nl}}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-form>
    <v-btn class="adv-calc-btn" @click="sendAdvancedInput">Geavanceerde berekening</v-btn>
    <v-alert v-if="calculationStatus === 'succes'" type="info" density="compact" variant="outlined" class="mt-3 mb-0">
      De geavanceerde berekening wordt uitgevoerd met de ingevulde velden.
    </v-alert>
    <v-alert v-if="calculationStatus === 'error'" type="error" density="compact" variant="outlined" class="mt-3 mb-0">
      Er missen nog gegevens in de bovenstaande velden en de geavanceerde berekening kon nog niet worden uitgevoerd.
    </v-alert>
    <v-alert v-if="calculationStatus === 'errorMessage'" type="error" density="compact" variant="outlined" class="mt-3 mb-0">
      {{ errorMessage }}
    </v-alert>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import _ from 'lodash'
import { required, minmax, type } from '@/utils/rules'

export default {
  props: {
    input: {
      type: Object
    },
    errorMessage: {
      type: String
    },
    location: {
      type: Object
    }
  },
  data () {
    return {
      results: [],
      calculationStatus: null, // 'succes', 'error', 'errorMessage'
      groupedInputFields: [],
      required,
      minmax,
      type
    }
  },
  watch: {
    '$route.params.calculationType' () {
      this.setGroupedInputFields()
    },
    errorMessage () {
      if (this.errorMessage !== '') {
        this.calculationStatus = 'errorMessage'
      }
    },
    locationDetails: {
      handler () {
        this.setGroupedInputFields()
      },
      deep: true
    },
    calcInput: {
      handler () {
        this.setGroupedInputFields()
      },
      deep: true
    },
    'calcInput.w_conc_background.data': {
      handler () {
        this.setGroupedInputFields()
      },
      deep: true
    },
    regionalWaterBodies: {
      handler () {
        this.setGroupedInputFields()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['calcInput', 'advancedCalcInput', 'totalAdvancedCalcInput', 'intakeInput',
      'locationDetails', 'requiredAdvancedFields', 'requiredTemperatureFields', 'waterbodiesDetails',
      'regionalWaterBodies', 'groupTitles']),
    inputFields () {
      let inputFields = ''
      if (this.$route.params.calculationType === 'immissietoets') {
        inputFields = 'advancedCalcInput'
      } else if (this.$route.params.calculationType === 'temperatuurlozing') {
        inputFields = 'temperatureCalcInput'
      } else {
        inputFields = 'advancedCalcInput'
      }
      return inputFields
    }
  },
  mounted () {
    this.setGroupedInputFields()
  },
  methods: {
    ...mapMutations(['addAdvancedCalcInput', 'addTemperatureCalcInput']),
    checkDefault (input) {
      if (!input.data && (input.default || parseFloat(input.default) === 0.0)) {
        input.data = input.default
      }
    },
    checkUpdateField (input) {
      let color = 'navbar'
      if (parseFloat(input.data) === parseFloat(input.default) || (input.data === input.default)) {
        color = undefined
      }
      return color
    },
    toTitle (val) {
      return _.startCase(val)
    },
    simpleCalcInput (inputName) {
      if (this.$route.params.calculationType === 'temperatuurlozing') {
        const exceptions = ['longitude', 'latitude', 'watertype']
        return exceptions.includes(inputName)
      } else {
        // Check whether the input field is already defined in the simple calculation
        const exceptions = ['w_width', 'w_additional_discharges', 'w_flow']
        return _.has(this.calcInput, inputName) && !exceptions.includes(inputName)
      }
    },
    requiredField (inputName) {
      // Check whether the input field is required
      if (this.$route.params.calculationType === 'temperatuurlozing') {
        return this.requiredTemperatureFields.includes(inputName)
      }
      return this.requiredAdvancedFields.includes(inputName)
    },
    findFieldByTitle (title) {
      let groupName = null
      let param = null
      Object.entries(this.totalAdvancedCalcInput(this.inputFields)).forEach(group => {
        const findParam = Object.values(group[1].properties).find(field => {
          return field.title === title
        })
        if (findParam) {
          groupName = group[0]
          param = findParam
        }
      })
      return { param, groupName }
    },
    updateAdvancedInput (paramName, value, group) {
      if (paramName === 'w_krw_water_type') {
        this.setGroupedInputFields()
      }
      // The v-model of the textfields are now only updating local groupedAdvInput.
      // This function also updates the advancedCalcInput in the store
      // If a simple calculation input field has changed, also change the calcInput
      if (_.has(this.calcInput, paramName)) {
        _.set(this.calcInput, `${paramName}.data`, value)
      }

      this.addInput({ param: paramName, group, props: { data: value, userInput: true } })
    },
    checkRelation (input) {
      const relation = _.get(input, 'properties.relation')
      if (relation) {
        const { groupName } = this.findFieldByTitle(relation.related_param)
        const calculation = relation.calculation.map(val => {
          const value = _.get(this.findFieldByTitle(val), 'param.data', val) || NaN
          return value.toString()
        })

        /* eslint-disable no-eval */
        const relatedParamValue = eval(calculation.join(' ')).toFixed(2)
        this.updateAdvancedInput(relation.related_param, relatedParamValue, groupName)
      }
    },
    addInput (params) {
      if (this.$route.params.calculationType === 'immissietoets') {
        this.addAdvancedCalcInput(params)
      } else if (this.$route.params.calculationType === 'temperatuurlozing') {
        this.addTemperatureCalcInput(params)
      } else {
        this.addAdvancedCalcInput(params)
      }
      this.$emit('changed-input')
    },
    sendAdvancedInput () {
      // Parse the input fields into the correct format for the endpoint
      this.$refs.advancedForm.validate()
      if (this.$refs.advancedForm.isValid) {
        this.calculationStatus = 'succes'
        const advancedInputs = {}

        Object.entries(this.groupedInputFields).forEach(group => {
          advancedInputs[group[0]] = {}
          Object.entries(group[1]).forEach(subgroup => {
            Object.entries(subgroup[1]).forEach(field => {
              if (field[1].type === 'number' & !isNaN(field[1].data)) {
                field[1].data = parseFloat(field[1].data)
              }
              advancedInputs[group[0]][field[0]] = field[1].data
            })
          })
        })
        this.$emit('advanced-input-completed', advancedInputs)
      } else {
        this.calculationStatus = 'error'
      }
    },
    setGroupedInputFields () {
      const advInputs = {}
      const calcInput = { ...this.advancedCalcInput(this.inputFields) }
      const watertype = _.get(this.$store.state.calcInput, 'watertype.data')

      Object.entries(calcInput).forEach(group => {
        advInputs[group[0]] = {}
        // First, let's get all the different subGroups in one list per group
        let groups = Object.entries(group[1]).map(subGroup => {
          return _.get(subGroup[1], 'properties.sub_group_nl', group[0])
        })
        groups = _.uniq(groups)
        groups.forEach(groupName => {
          const subGroup = {}
          Object.entries(group[1]).forEach(input => {
            // TODO: some fields need initially be filled (from different sources, location/background)
            // Check whether this value should come from 'locations'
            const source = _.get(input[1], 'properties.source')
            const nwm = _.get(input[1], 'properties.nwm_available')

            if (source === 'drinkwater') {
              // Update only the data field if the user has not changed it manually
              // or the data field is empty
              const defaultInput = _.get(this.intakeInput, `['${input[0]}'].data`)
              if (!input[1].userInput) {
                input[1].data = defaultInput
              }
              input[1].default = defaultInput
            }

            if (source === 'locations') {
              const defaultInp = _.get(this.locationDetails, input[0])
              // Update only the data field if the user has not changed it manually
              // or the data field is empty
              if (!input[1].userInput) {
                input[1].data = defaultInp
                input[1].default = defaultInp
              }
            }
            // Use user location for lat/lng
            // TODO: we might want to return the lat/lng in the selected input. Now it uses the model.py default
            if (source === 'user') {
              if (input[0] === 'longitude') {
                input[1].data = this.location.lng
              }
              if (input[0] === 'latitude') {
                input[1].data = this.location.lat
              }
            }
            // If the source is waterbodies, get the props from the waterbodies.
            if (source === 'waterbodies') {
              const defaultInp = _.get(this.waterbodiesDetails, input[0])
              // Update only the data field if the user has not changed it manually
              // or the data field is empty
              if (!input[1].userInput) {
                input[1].data = defaultInp
              }
              input[1].default = defaultInp
            }

            if (source === 'krw') {
              const defaultInp = _.get(this.regionalWaterBodies, input[0])
              // Update only the data field if the user has not changed it manually
              // or the data field is empty
              if (!input[1].userInput) {
                input[1].data = defaultInp
              }
              input[1].default = defaultInp
            }

            // Check whether this value should come from simpleCalc or not
            const hasSimpleInp = _.has(this.calcInput, `${input[0]}`)
            const simpleInp = _.get(this.calcInput, `${input[0]}.data`, null)
            if (hasSimpleInp) {
              // Update only the data field if the user has not changed it manually
              // or the data field is empty
              if (!input[1].userInput) {
                input[1].data = simpleInp
              }

              input[1].default = _.get(this.calcInput, `${input[0]}.default`)
            }

            if (nwm && _.get(nwm, 'watertypes', []).includes(watertype)) {
              // If nwm is available and the selected watertype allows for the nwm data
              // overwrite the drinkwater source for these values
              const defaultInp = _.get(input[1], 'nwmdata')
              if (!input[1].userInput) {
                input[1].data = defaultInp
              }
              input[1].default = defaultInp
            }

            // Make sure the textfields are triggered if null (null is not detected, use ' ')
            if (input[1].data === null || input[1].data === '') {
              input[1].data = undefined
            }

            if (input[1].default === null || input[1].default === '') {
              input[1].default = undefined
            }

            // Divide into groups
            if (_.get(input[1], 'properties.sub_group_nl') === groupName || groupName === group[0]) {
              subGroup[input[0]] = input[1]
            }

            this.checkRelation(input[1])
          })
          advInputs[group[0]][groupName] = subGroup
        })
      })
      this.groupedInputFields = advInputs
    },
    filteredSubGroup (subGroup) {
      const filteredGroup = {}
      Object.entries(subGroup).forEach(input => {
        if (!this.simpleCalcInput(input[0])) {
          filteredGroup[input[0]] = input[1]
        }
      })
      return filteredGroup
    }
  }
}
</script>

<style>
.input-field {
  width: 100%;
}
.checkbox .v-label{
  font-size: 12px !important;
}
</style>
